import "./Footer.css"

const Footer = () =>{

    return(
      <div className="footerbody">
      <div className="row justify-content-between">
          <div className="col-md-8 d-flex flex-wrap">
              <span className="percentage me-4">© Recess Private Limited</span>
              <span className="percentage me-4">
                  <img className="icons" src={require("../../Assets/lib.png")}/> The Library
              </span>
              <span className="percentage me-4">
                  <img className="icons" src={require("../../Assets/email.png")}/> Get in Touch
              </span>
              <span className="percentage me-4">
                  <img className="icons" src={require("../../Assets/termsandconditions.png")}/> Terms & Conditions
              </span>
              <span className="percentage me-4">
                  <img className="icons" src={require("../../Assets/policy.png")}/> Privacy Policy
              </span>
          </div>
          <div className="col-md-3 text-md-end text-start">
              <span className="percentage">
                  <img className="icons" src={require("../../Assets/learnatrecess.png")}/> Learn at Recess.
              </span>
          </div>
      </div>
  </div>
  
    );
}
export default Footer;
