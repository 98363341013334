import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React,{useState}from 'react'
import './PaymentModal.css'
import { Modal } from 'react-bootstrap';
import { API_URL } from '../../server';
import { ToastContainer, toast } from 'react-toastify';
export default function PaymentModal({stripmodal,closestripModal,price,cuurency,}) {

    const [clientSecret, setClientSecret] = useState('');
    const [striploading, setStriploading] = useState(false);
    const [IsProcessing,setIsProcessing] = useState(false);
    const [sessionCount, setSessionCount] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const data = [
        { title: 'Session', amount: 1 },
        { title: 'Session', amount: 3 },
        { title: 'Session', amount: 6 },
        { title: 'Session', amount: 12 },
    ]

    const CalulateAmount = (data) => {
        const sessionData = Number(data);
        const sessionPrice = Number(price);
        const amount = sessionData * sessionPrice;
        const TotalAmount = amount * 100;
        console.log("CalculateAmount", TotalAmount)
        return TotalAmount;
    }


    const paymentintent = async (data) => {
        try {
            const registerid = sessionStorage.getItem('registerid');
            setStriploading(true);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "customerId": registerid,
                "amount": CalulateAmount(data?.amount),
                "currency": cuurency.toLocaleUpperCase(),
                "sessionCount": data?.amount 
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            console.log("raw", raw);
            const response = await fetch(API_URL + "createPaymentIntent", requestOptions);
            const result = await response.json();
            console.log("createPaymentIntent", result);
            if (!response.ok) {
                throw new Error("Failed to create payment intent");
            }
            setClientSecret(result?.paymentIntent?.client_secret);
            setSessionCount(data?.amount);
            setStriploading(false);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setStriploading(false);
        }
    }


    const handelPayment = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error("Stripe has not loaded yet.");
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            console.error("CardElement is not loaded.");
            return;
        }

        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret, 
                {
                    payment_method: {
                        card: cardElement,
                    },
                }
            );

            if (error) {
                // setError(error.message);
                console.error("Payment failed:", error.message);
            } else if (paymentIntent && paymentIntent.status === "succeeded") {
                console.log("Payment succeeded:", paymentIntent);
                handlesession();
            }
        } catch (err) {
            toast.error(err.message);
            console.error("Error during payment processing:", err.message);
        }
    };

    const handlesession = async () => {
        try {
            const registerid = sessionStorage.getItem('registerid');

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "user_id": registerid,
                "sessionCount": sessionCount
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            const response = await fetch(API_URL + 'addWalletCount', requestOptions);
            const result = await response.json();
            if (!response.ok) {
                throw new Error("somenthing went wrong");
            }
            console.log("addWalletCount", result);
            toast.success('Payment successfully',{
                autoClose: 1000,
                onClose:()=> {
                    closestripModal();
                }
              })
              setIsProcessing(false);
        } catch (error) {
            console.log("addWallet", error);
        } finally {
            setIsProcessing(false);
        }
    }
  return (
    <Modal show={stripmodal} className="mt-5">
    <div className="modalbackgroundcolor modalhightandwidth">
        <div className="stripmodal-head">
            <div className="btn btn-close ms-auto" onClick={closestripModal}>
            </div>
        </div>
        <div className="stripmodal-body">
            <span>Your account is ready.</span>
            <span>Purchase a learning pack</span>
            <span>Top-up your wallet with sessions that you can use anytime across all subjects without an expiry date.</span>
        </div>
        <div className="stripmodal-line-wrapper">
            <hr />
        </div>
        {!clientSecret ? (
            <div className="stripmodal-card-wrapper">
                {data?.map((items, i) => (
                    <div key={i} className="stripmodal-crad" onClick={() => paymentintent(items)}>
                        <span>{items?.amount}</span>
                        <span>{items?.title}</span>
                    </div>
                ))}
            </div>
        ) :
            striploading ? (
                // <div className='d-flex justify-content-center pt-5'>
                    <div class="spinner-border text-dark" role="status"></div>
                // </div>
            ) : (
                    <div className="payment-conatiner">
                        <div className="card-elemt-container" >
                            <CardElement  />
                        </div>

                        <div className="procedtopay-button-conatiner">
                            <button className="procedtopay-button" onClick={handelPayment}>
                                Proceed to pay
                            </button>
                        </div>
                    </div>
                )}
        <div className="stripmodal-footer">
            <span onClick={closestripModal}>Skip for now</span>
        </div>
    </div>
</Modal>
  )
}
