import "./CourseCancilBooking.css";

const CourseCancilBooking = () =>{

    return(
        <div>
            CourseCancilBooking
        </div>
    );
}
export default CourseCancilBooking;