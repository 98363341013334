import "./Line.css"

const Line = (props) =>{

    return(
        <div>
            <p className="line" style={{marginTop:props.margintop}}></p>
        </div>
    );
}

export default Line;