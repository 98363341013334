import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

//fonts started
import "./Components/font-family/AvenirNextLTPro-Regular.ttf";
import "./Components/font-family/AvenirNextLTPro-Bold.ttf";
import "./Components/font-family/recoleta-regulardemo.ttf";
import "./Components/font-family/RecoletaBold.ttf";
import "./Components/font-family/Recoleta-Medium.ttf"
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { OauthClientId } from './server';

//fonts ended

const root = ReactDOM.createRoot(document.getElementById('root'));
// In video call, set mode to "rtc"
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

// Register Firebase Messaging service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

root.render(
  // <GoogleOAuthProvider clientId={OauthClientId}>
  <React.StrictMode>
     <AgoraRTCProvider client={client}>
    <App />
    </AgoraRTCProvider>
  </React.StrictMode>
  // </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

