import { useState } from 'react';
import "./SettingContentSupport.css"

const SettingContentSupport = () => {

  const [peerMessage, setPeerMessage] = useState();
  const [Messages, setMessages] = useState();

  return (
    <div className='chatscreen'>
      <div className='chat-mainconatiner'>
        {/* <button onClick={()=>handleLogin()}>login chat </button> */}
        <div className='chat-body-conatiner' >
          {Messages?.map((message, index) => (
            <>

              <div key={index} >
                <div className="message-text">
                  {''}
                </div>
                <div className="message-time-text">
                  {/* {formatDate(message?.time)} */}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className='chat-footer'>
        <div className='chat-inputbox-conatiner'>
          <input placeholder='Type a message...'
            value={peerMessage}
            onChange={(e) =>
              setPeerMessage(e.target.value)
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // handleSendPeerMessage();
              }
            }}
          />
        </div>
        <div className='chat-sendicon'>
          <img src={require("../../../Assets/chat-send.png")}
            className='sendIcon'
          // onClick={() => handleSendPeerMessage()}
          />
        </div>
      </div>
    </div>
  )
}
export default SettingContentSupport;