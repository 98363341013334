import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage,deleteToken } from 'firebase/messaging';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDu2OWBbu7hAh64GM9j6Ftd1D7hKBnuM5k",
  authDomain: "recess-68407.firebaseapp.com",
  projectId: "recess-68407",
  storageBucket: "recess-68407.firebasestorage.app",
  messagingSenderId: "514128207933",
  appId: "1:514128207933:web:1eb676e57785257a6dca54",
  measurementId: "G-T2QH949G02"
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();


export const getFirebaseToken = (setTokenFound) => {
    // Check if there's already a token in sessionStorage
    // const existingToken = sessionStorage.getItem("firebaseToken");
    // if (existingToken) {
    //   console.log("Using existing token:", existingToken);
    //   setTokenFound(existingToken);
    //   return; // Stop here if a token already exists
    // }
    // Request permission to show notifications
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          // User has granted permission, now retrieve the token
          return getToken(messaging, { vapidKey: "BM4uML5txBLAVLOq6Fai3oGBQYVxsJQ9kOvOkqBqiELAo6IYJgxUsjkQJ3AEVY4JErThQ_PSREWbBZUeg8aFbX8" });
        } else {
          console.log('Permission denied for notifications.');
          setTokenFound(false);
          return null; // No token generated due to permission denial
        }
      })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Current Token:',currentToken);
          setTokenFound(currentToken);
          // sessionStorage.setItem("firebaseToken", currentToken);
        } else {
          console.log('No registration token available.');
          setTokenFound(false);
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        setTokenFound(false);
      });
  };

  export const deleteFirebaseToken = async (setTokenFound) => {
    try {
      const tokenDeleted = await deleteToken(messaging);
      if (tokenDeleted) {
        console.log("FCM token deleted successfully.");
        setTokenFound(false);
      } else {
        console.log("FCM token deletion unsuccessful.");
      }
    } catch (error) {
      console.error("Error deleting FCM token:", error);
      setTokenFound(false);
    }
  };


  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

    export { auth, googleProvider };